var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center flex-wrap mr-1"},[_c('p',{staticClass:"my-2 mr-5"},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.transChoice('global.shortcut', 2)))+": ")]),_c('div',{ref:"kt_header_menu",staticClass:"header-menu header-menu-mobile",attrs:{"id":"kt_header_menu"}},[_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/templates","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.trans('nav.aside.templates.all'))+" ")])])])]}}])}),_c('router-link',{attrs:{"to":"/templates/add","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",on:{"click":function($event){return _vm.$bvModal.show('modal-templates-add')}}},[_c('span',{staticClass:"menu-text"},[_vm._v(" "+_vm._s(_vm.trans('nav.aside.templates.add'))+" ")])])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }