<template>
  <div class="d-flex align-items-center flex-wrap mr-1">
    <p class="my-2 mr-5">
      {{ transChoice('global.shortcut', 2) | capitalize }}:
    </p>
    <div
      id="kt_header_menu"
      ref="kt_header_menu"
      class="header-menu header-menu-mobile"
    >
      <ul class="menu-nav">
        <!-- begin:: Lag nytt templat -->
        <router-link
          v-slot="{ href, navigate, isActive, isExactActive }"
          to="/templates"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <span class="menu-text">
                {{ trans('nav.aside.templates.all') }}
              </span>
            </a>
          </li>
        </router-link>
        <!-- end:: Lag nytt templat -->

        <!-- begin:: Lag nytt templat -->
        <router-link
          v-slot="{ isActive, isExactActive }"
          to="/templates/add"
          custom
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active'
            ]"
          >
            <a class="menu-link" @click="$bvModal.show('modal-templates-add')">
              <span class="menu-text">
                {{ trans('nav.aside.templates.add') }}
              </span>
            </a>
          </li>
        </router-link>
        <!-- end:: Lag nytt templat -->
      </ul>
    </div>
  </div>
</template>

<script>
  import { trans, transChoice } from '@src/core/helpers/translate'

  export default {
    name: 'SubHeaderDashboard',
    setup () {
      return {
        trans,
        transChoice
      }
    }
  }
</script>
